import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../apiConfigLogin';

function ApartamentoList({ onEdit, onDelete, refresh }) {
    const [apartamentos, setApartamentos] = useState([]);

    useEffect(() => {
        api.get(`/apartamentos`)
            .then(response => setApartamentos(response.data))
            .catch(error => console.error(error));
    }, [refresh]);

    // Função para determinar a cor do Paper com base no status
    const getPaperColor = (status) => {
        switch (status) {
            case 'livre':
                return 'lightgreen'; // Cor verde para "livre"
            case 'ocupado':
                return 'lightcoral'; // Cor vermelha para "ocupado"
            case 'limpeza':
                return 'lightblue'; // Cor azul para "limpeza"
            case 'manutencao':
                return 'mediumslateblue'; // Cor azul para "manutencao" (sem acento)
            default:
                return 'white'; // Cor padrão
        }
    };

    return (
        <Container>
            <Grid container spacing={4}>
                {apartamentos.map((apartamento) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={apartamento.id}>
                        <Paper 
                            elevation={3} 
                            style={{ 
                                padding: '20px', 
                                borderRadius: '10px', 
                                backgroundColor: getPaperColor(apartamento.status)
                            }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" fontWeight="bold">
                                    Apto {apartamento.numero}
                                </Typography>
                                <Box>
                                    <IconButton onClick={() => onEdit(apartamento)} color="success" size="small">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => onDelete(apartamento.id)} color="error" size="small">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                {apartamento.descricao}
                            </Typography>
                            <Typography variant="body1">
                                Capacidade: {apartamento.capacidade} pessoas
                            </Typography>
                            <Typography variant="body1">
                                Preço Base: R${apartamento.preco_base}
                            </Typography>
                            <Typography variant="body1">
                                Preço Criança: R${apartamento.preco_crianca}
                            </Typography>
                            <Typography variant="body1" color={apartamento.status === 'livre' ? 'green' : 'red'}>
                                Status: {apartamento.status}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default ApartamentoList;
