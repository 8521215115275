import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

function Dashboards() {
    useEffect(() => {
        document.title = "Dashboards | Sua Pousada";
    }, []);

    return (
        <div>
            <Typography variant="h4" style={{ display: 'flex', alignItems: 'center' }}>
                Dashboards
            </Typography>
        </div>
    );
}

export default Dashboards;
