import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import RegisterPage from './pages/RegisterPage'
import Home from './pages/Home';
import Apartamentos from './pages/Apartamentos';
import Reservas from './pages/Reservas';
import Despesas from './pages/Despesas';
import Hospedes from './pages/Hospedes';
import Dashboards from './pages/Dashboards';
import NavBar from './components/NavBar';
import theme from './theme/theme';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const ProtectedRoute = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/" />;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div style={{ display: 'flex' }}>
          <NavBar setIsLoggedIn={setIsLoggedIn} />
          <div style={{ flex: 1, marginLeft: '80px', padding: '20px' }}>
            <Routes>
              <Route path="/" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
              <Route path="/apartamentos" element={<ProtectedRoute element={<Apartamentos />} />} />
              <Route path="/reservas" element={<ProtectedRoute element={<Reservas />} />} />
              <Route path="/despesas" element={<ProtectedRoute element={<Despesas />} />} />
              <Route path="/hospedes" element={<ProtectedRoute element={<Hospedes />} />} />
              <Route path="/dashboards" element={<ProtectedRoute element={<Dashboards />} />} />
            </Routes>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
