import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../apiConfigLogin';

function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Redefinir Senha | Sua Pousada";
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      await api.post('/auth/forgot-password', { email });
      setSuccess('Email de redefinição de senha enviado. Verifique seu email para o código de redefinição.');
      setStep(1);
      setOpen(true);
    } catch (error) {
      setError('Erro ao enviar email de redefinição de senha. Verifique o email digitado.');
      setOpen(true);
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    try {
      if (newPassword !== confirmPassword) {
        setError('As senhas não coincidem.');
        setOpen(true);
        return;
      }
      await api.post('/auth/reset-password', { email, resetCode: verificationCode, newPassword });
      setSuccess('Senha alterada com sucesso.');
      setStep(0);
      setEmail('');
      setVerificationCode('');
      setNewPassword('');
      setConfirmPassword('');
      setOpen(true);
    } catch (error) {
      setError('Erro ao alterar senha. Tente novamente mais tarde.');
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isFormValid = () => {
    if (step === 0) {
      return email.trim() !== '';
    } else if (step === 1) {
      return verificationCode.trim() !== '';
    } else if (step === 2) {
      return newPassword !== '' && confirmPassword !== '' && newPassword === confirmPassword;
    }
    return false;
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Redefinir Senha
          </Typography>
          {step === 0 && (
            <Box component="form" onSubmit={handleResetPassword} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Endereço de Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isFormValid()}
              >
                Enviar Email de Redefinição
              </Button>
              <Grid container justifyContent="center">
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Button onClick={() => navigate('/login')} variant="text" fullWidth>
                    Voltar para o Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {step === 1 && (
            <Box component="form" onSubmit={() => setStep(2)} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="verificationCode"
                label="Código de Verificação"
                name="verificationCode"
                autoComplete="off"
                autoFocus
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isFormValid()} 
              >
                Verificar Código
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button onClick={() => navigate('/login')} variant="text">
                    Voltar para o Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {step === 2 && (
            <Box component="form" onSubmit={handleChangePassword} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="Nova Senha"
                type="password"
                id="newPassword"
                autoComplete="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirmar Nova Senha"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isFormValid()}
              >
                Alterar Senha
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button onClick={() => navigate('/login')} variant="text" >
                    Voltar para o Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={error ? 'error' : 'success'} sx={{ width: '100%' }}>
            {error || success}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default ResetPasswordPage;
