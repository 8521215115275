import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: 'linear-gradient(to top, khaki 30%, white 70%)',
          minHeight: '100vh',
          margin: 0,
          padding: 0,
        },
      },
    },
  },
});

export default theme;
