import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, CircularProgress, Modal, Box } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import api from '../apiConfigLogin';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarComponent2 = () => {
  const [apartamentos, setApartamentos] = useState([]);
  const [reservas, setReservas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReserva, setSelectedReserva] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [apartamentosResponse, reservasResponse] = await Promise.all([
          api.get('/apartamentos'),
          api.get('/reservas/todas')
        ]);
        setApartamentos(apartamentosResponse.data);
        setReservas(formatReservas(reservasResponse.data));
      } catch (error) {
        console.error('Erro ao buscar dados', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatReservas = (reservas) => {
    return reservas.map(reserva => ({
      id: reserva.id,
      title: `Reserva de ${reserva.hospede?.nome || 'Hóspede Desconhecido'}`,
      start: new Date(reserva.data_checkin),
      end: new Date(reserva.data_checkout),
      apartamentoId: reserva.apartamento_id,
      status: reserva.status,
      hospede: reserva.hospede,
      adultos: reserva.adultos,
      criancas: reserva.criancas,
    })).filter(event => event.title);
  };

  const handleCheckIn = async (reservaId) => {
    try {
      await api.post(`/reservas/${reservaId}/checkin`);
      setReservas(prevReservas => prevReservas.map(reserva => 
        reserva.id === reservaId ? { ...reserva, status: 'confirmada' } : reserva
      ));
      closeModal();
    } catch (error) {
      console.error('Erro ao confirmar check-in', error);
    }
  };

  const getEventStyle = (event) => {
    const statusColors = {
      cancelada: 'red',
      pendente: 'dodgerblue',
      reservada: 'yellow',
      confirmada: 'green',
      finalizada: 'grey',
    };
    return {
      backgroundColor: statusColors[event.status] || 'lightblue',
      border: '1px solid blue',
      borderRadius: '5px',
      opacity: 0.8,
      display: 'block',
    };
  };

  const openReservaModal = (event) => {
    setSelectedReserva(event);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedReserva(null);
  };

  const apartamentosPorStatus = apartamentos.reduce((acc, apartamento) => {
    const status = apartamento.status;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(apartamento);
    return acc;
  }, {});

  return (
    <Container>
      
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              Reservas
            </Typography>
            <Calendar
              localizer={localizer}
              events={reservas}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              selectable
              onSelectEvent={openReservaModal}
              eventPropGetter={(event) => ({
                style: getEventStyle(event)
              })}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h5" gutterBottom>
              Apartamentos
            </Typography>
            {Object.keys(apartamentosPorStatus).map(status => (
              <div key={status}>
                <Typography variant="h6">{status.charAt(0).toUpperCase() + status.slice(1)}</Typography>
                {apartamentosPorStatus[status].map(apartamento => (
                  <Typography key={apartamento.id} style={{ margin: '5px 0' }}>
                    Apartamento {apartamento.numero}: {apartamento.status}
                  </Typography>
                ))}
              </div>
            ))}
          </Grid>
        </Grid>
      )}

      <Modal open={openModal} onClose={closeModal}>
        <Box sx={{ p: 2, backgroundColor: 'white', margin: 'auto', width: 400, borderRadius: '8px' }}>
          {selectedReserva && (
            <>
              <Typography variant="h6">Detalhes da Reserva</Typography>
              <Typography>Hóspede: {selectedReserva.hospede?.nome || 'Hóspede Desconhecido'}</Typography>
              <Typography>Check-in: {moment(selectedReserva.start).format('DD/MM/YYYY')}</Typography>
              <Typography>Check-out: {moment(selectedReserva.end).format('DD/MM/YYYY')}</Typography>
              <Typography>Adultos: {selectedReserva.adultos}</Typography>
              <Typography>Crianças: {selectedReserva.criancas}</Typography>
              <Typography>Status: {selectedReserva.status}</Typography>
              {selectedReserva.status === 'reservada' && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleCheckIn(selectedReserva.id)}
                  sx={{ mt: 2, mr: 10 }}
                >
                  Confirmar Check-in
                </Button>
              )}
              <Button variant="contained" onClick={closeModal} sx={{ mt: 2 }}>
                Fechar
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default CalendarComponent2;
