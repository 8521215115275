import React, { useState } from 'react';
import { TextField, MenuItem, IconButton, Box, Snackbar, Alert } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import api from '../apiConfigLogin';

function BuscarReserva({ onBuscar }) {
    const [criterio, setCriterio] = useState('hospede');
    const [valor, setValor] = useState('');
    const [checkin, setCheckin] = useState('');
    const [checkout, setCheckout] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showBusca, setShowBusca] = useState(false);

    const handleBuscar = () => {
        const baseRota = `/reservas/search`;
        let rotaBusca = '';

        // Lógica de busca baseada no critério
        if (criterio === 'apartamento') {
            rotaBusca = `${baseRota}/apartamento?numero=${encodeURIComponent(valor.trim())}`;
        } else if (criterio === 'hospede') {
            rotaBusca = `${baseRota}/hospede?nome=${encodeURIComponent(valor.trim())}`;
        } else if (criterio === 'data_checkin') {
            // Enviar data de check-in e check-out
            rotaBusca = `${baseRota}/datas?checkin=${formatarDataParaAPI(checkin)}&checkout=${formatarDataParaAPI(checkout)}`;
        } else {
            setSnackbarMessage('Critério de busca inválido.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        api.get(rotaBusca)
            .then(response => {
                onBuscar(response.data);
                setSnackbarMessage('Busca realizada com sucesso!');
                setSnackbarSeverity('success');
            })
            .catch(error => {
                console.error('Erro ao buscar reservas:', error);
                setSnackbarMessage('Erro ao buscar reservas.');
                setSnackbarSeverity('error');
            })
            .finally(() => {
                setSnackbarOpen(true);
                setValor('');
                setCheckin('');
                setCheckout(''); // Limpa os campos de data após a pesquisa
            });
    };

    const handleRefresh = () => {
        api.get(`/reservas`)
            .then(response => {
                onBuscar(response.data);
                setSnackbarMessage('Lista de reservas atualizada!');
                setSnackbarSeverity('success');
            })
            .catch(() => {
                setSnackbarMessage('Erro ao atualizar reservas.');
                setSnackbarSeverity('error');
            })
            .finally(() => {
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleToggleBusca = () => {
        setShowBusca(prev => !prev);
    };

    // Função para formatar a data para o formato AAAA-MM-DD
    const formatarDataParaAPI = (data) => {
        if (!data) return '';
        const [dia, mes, ano] = data.split('/');
        return `${ano}-${mes}-${dia}`; // Formato: AAAA-MM-DD
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton onClick={handleToggleBusca}>
                <SearchIcon />
            </IconButton>

            {showBusca && (
                <>
                    <TextField
                        select
                        label="Critério"
                        value={criterio}
                        onChange={(e) => {
                            setCriterio(e.target.value);
                            setValor(''); // Limpa o valor ao mudar o critério
                            setCheckin('');
                            setCheckout('');
                        }}
                        sx={{ minWidth: 150 }}
                        InputProps={{
                            style: { height: 40 } // Defina a altura aqui
                        }}
                    >
                        <MenuItem value="hospede">Hóspede</MenuItem>
                        <MenuItem value="apartamento">Apartamento</MenuItem>
                        <MenuItem value="data_checkin">Check-in</MenuItem>
                        <MenuItem value="data_checkout">Check-out</MenuItem>
                    </TextField>

                    {criterio === 'data_checkin' && (
                        <>
                            <TextField
                                label="Check-in (DD/MM/AAAA)"
                                value={checkin}
                                onChange={(e) => setCheckin(e.target.value)}
                                sx={{ minWidth: 200 }}
                                InputProps={{
                                    style: { height: 40 } // Defina a altura aqui
                                }}
                            />
                            <TextField
                                label="Check-out (DD/MM/AAAA)"
                                value={checkout}
                                onChange={(e) => setCheckout(e.target.value)}
                                sx={{ minWidth: 200 }}
                                InputProps={{
                                    style: { height: 40 } // Defina a altura aqui
                                }}
                            />
                        </>
                    )}

                    {(criterio === 'apartamento' || criterio === 'hospede') && (
                        <TextField
                            label={`Buscar por ${criterio}`}
                            value={valor}
                            onChange={(e) => setValor(e.target.value)}
                            sx={{ minWidth: 400 }}
                            InputProps={{
                                style: { height: 40 } // Defina a altura aqui
                            }}
                        />
                    )}

                    <IconButton color="primary" onClick={handleBuscar}>
                        <SearchIcon />
                    </IconButton>

                    <IconButton color="primary" onClick={handleRefresh}>
                        <RefreshIcon />
                    </IconButton>
                </>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BuscarReserva;
