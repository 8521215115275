import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { parseISO, format } from 'date-fns';
import api from '../apiConfigLogin';

function ReservaList({ onEdit, onDelete, refresh, reservas, setReservas }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const fetchReservas = async (page) => {
        try {
            const response = await api.get(`/reservas?page=${page + 1}&limit=${rowsPerPage}`);
            setReservas(response.data.reservas);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Erro ao carregar reservas:', error);
        }
    };

    useEffect(() => {
        fetchReservas(currentPage);
    }, [currentPage, rowsPerPage, refresh]);

    // Função para capitalizar a primeira letra do status
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // Mapeia os status para cores
    const statusColors = {
        cancelada: 'lightcoral',
        pendente: 'lightgreen',
        reservada: 'lightblue', 
        confirmada: 'green',
        finalizada: 'grey',
    };

    return (
        <>
            {/* Paginação na parte superior */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]} // Opções de limite por página
                component="div"
                count={totalPages * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={(event, newPage) => setCurrentPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setCurrentPage(0);
                }}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Apartamento</TableCell>
                            <TableCell>Hóspede</TableCell>
                            <TableCell>Check-in</TableCell>
                            <TableCell>Check-out</TableCell>
                            <TableCell>Adultos</TableCell>
                            <TableCell>Crianças</TableCell>
                            <TableCell>Valor Total</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reservas.map((reserva) => (
                            <TableRow
                                key={reserva.id}
                                style={{ backgroundColor: statusColors[reserva.status] || 'white' }}
                            >
                                <TableCell>{reserva.apartamento.numero}</TableCell>
                                <TableCell>{reserva.hospede.nome}</TableCell>
                                <TableCell>{format(parseISO(reserva.data_checkin), 'dd/MM/yyyy')}</TableCell>
                                <TableCell>{format(parseISO(reserva.data_checkout), 'dd/MM/yyyy')}</TableCell>
                                <TableCell>{reserva.adultos}</TableCell>
                                <TableCell>{reserva.criancas}</TableCell>
                                <TableCell>R${parseFloat(reserva.total).toFixed(2)}</TableCell>
                                <TableCell>{capitalizeFirstLetter(reserva.status)}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onEdit(reserva)} color="success">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => onDelete(reserva.id)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ReservaList;
