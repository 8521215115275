import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, Dialog, MenuItem } from '@mui/material';
import api from '../apiConfigLogin';

function CriarApartamento({ apartamento, onClose, onSave }) {
    const [numero, setNumero] = useState('');
    const [descricao, setDescricao] = useState('');
    const [capacidade, setCapacidade] = useState('');
    const [precoBase, setPrecoBase] = useState('');
    const [precoCrianca, setPrecoCrianca] = useState('');
    const [status, setStatus] = useState('livre');

    useEffect(() => {
        if (apartamento) {
            setNumero(apartamento.numero);
            setDescricao(apartamento.descricao);
            setCapacidade(apartamento.capacidade);
            setPrecoBase(apartamento.preco_base);
            setPrecoCrianca(apartamento.preco_crianca);
            setStatus(apartamento.status);
        } else {
            setNumero('');
            setDescricao('');
            setCapacidade('');
            setPrecoBase('');
            setPrecoCrianca('');
            setStatus('livre');
        }
    }, [apartamento]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const novoApartamento = {
            numero,
            descricao,
            capacidade: parseInt(capacidade, 10),
            preco_base: parseFloat(precoBase),
            preco_crianca: parseFloat(precoCrianca),
            status
        };

        const url = apartamento
            ? `/apartamentos/${apartamento.id}`  // Editar apartamento
            : `/apartamentos`;  // Criar novo apartamento

        const method = apartamento ? 'put' : 'post';

        api[method](url, novoApartamento)
            .then(response => {
                onSave(true, 'Apartamento salvo com sucesso!');
                onClose();
            })
            .catch(error => {
                console.error('Erro ao salvar apartamento:', error);
                onSave(false, 'Erro ao salvar apartamento. Tente novamente.');
            });
    };

    return (
        <Dialog open={!!apartamento || apartamento === null} onClose={onClose} maxWidth="sm" fullWidth>
            <Container
                sx={{
                    padding: '30px'
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    {apartamento ? 'Editar Apartamento' : 'Criar Apartamento'}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Número do Apartamento"
                        value={numero}
                        onChange={(e) => setNumero(e.target.value)}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Descrição"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Capacidade"
                        value={capacidade}
                        onChange={(e) => setCapacidade(e.target.value)}
                        fullWidth
                        required
                        type="number"
                    />
                    <TextField
                        label="Preço Base"
                        value={precoBase}
                        onChange={(e) => setPrecoBase(e.target.value)}
                        fullWidth
                        required
                        type="number"
                    />
                    <TextField
                        label="Preço Criança"
                        value={precoCrianca}
                        onChange={(e) => setPrecoCrianca(e.target.value)}
                        fullWidth
                        required
                        type="number"
                    />
                    <TextField
                            select
                            label="Status"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="livre">Livre</MenuItem>
                            <MenuItem value="ocupado">Ocupado</MenuItem>
                            <MenuItem value="limpeza">Limpeza</MenuItem>
                            <MenuItem value="manutencao">Manutenção</MenuItem>
                        </TextField>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                        <Button onClick={onClose} variant="outlined" color="error" sx={{ mr: 2 }}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {apartamento ? 'Salvar Alterações' : 'Criar Apartamento'}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Dialog>
    );
}

export default CriarApartamento;
