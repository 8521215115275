import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, MenuItem, Autocomplete, Box, Dialog, Alert } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatISO, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import api from '../apiConfigLogin';

function CriarReserva({ reserva, onClose, onSave }) {
    const [apartamentos, setApartamentos] = useState([]);
    const [hospedes, setHospedes] = useState([]);
    const [apartamentoSelecionado, setApartamentoSelecionado] = useState(reserva?.apartamento || null);
    const [hospedeSelecionado, setHospedeSelecionado] = useState(reserva?.hospede || null);
    const [valorTotal, setValorTotal] = useState(reserva?.total || 0);
    const [desconto, setDesconto] = useState(reserva ? reserva.desconto || 0 : 0);
    const [checkin, setCheckin] = useState(reserva?.data_checkin ? parseISO(reserva.data_checkin) : null);
    const [checkout, setCheckout] = useState(reserva?.data_checkout ? parseISO(reserva.data_checkout) : null);
    const [adultos, setAdultos] = useState(reserva?.adultos || 1);
    const [criancas, setCriancas] = useState(reserva?.criancas || 0);
    const [status, setStatus] = useState(reserva?.status || 'pendente');
    const [error, setError] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);

    const calcularValorTotal = () => {
        if (apartamentoSelecionado && checkin && checkout) {
            const diasEstadia = Math.ceil((checkout - checkin) / (1000 * 60 * 60 * 24));
            const valorSemDesconto =
                ((apartamentoSelecionado.preco_base * adultos) + (apartamentoSelecionado.preco_crianca * criancas)) * diasEstadia;
            return Math.max(0, valorSemDesconto - desconto);
        }
        return 0;
    };

    useEffect(() => {
        api.get(`/apartamentos`)
            .then(response => setApartamentos(response.data))
            .catch(error => console.error('Erro ao carregar apartamentos:', error));

        api.get(`/hospedes`)
            .then(response => setHospedes(response.data))
            .catch(error => console.error('Erro ao carregar hóspedes:', error));
    }, []);

    useEffect(() => {
        setValorTotal(calcularValorTotal());
    }, [apartamentoSelecionado, adultos, criancas, checkin, checkout, desconto]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        const novaReserva = {
            apartamento_id: apartamentoSelecionado.id,
            hospede_id: hospedeSelecionado?.id,
            data_checkin: formatISO(checkin),
            data_checkout: formatISO(checkout),
            adultos,
            criancas,
            total: valorTotal,
            status,
        };

        const request = reserva
            ? api.put(`/reservas/${reserva.id}`, novaReserva)
            : api.post(`/reservas`, novaReserva);

        request
            .then(response => {
                onSave(true, 'Reserva salva com sucesso');
                onClose();
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    const errorMsg = error.response.data.message;
                    if (error.response.data.apartamentosDisponiveis) {
                        setSuggestions(error.response.data.apartamentosDisponiveis);
                    }
                    setError(errorMsg);
                } else {
                    setError('Erro ao salvar reserva. Tente novamente.');
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Dialog open={!!reserva || reserva === null} onClose={onClose} maxWidth="sm" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                <Container sx={{ padding: '30px' }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        {reserva ? 'Editar Reserva' : 'Criar Reserva'}
                    </Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    {suggestions && suggestions.length > 0 && (
                        <Alert severity="info">
                            Apartamentos disponíveis: {Array.isArray(suggestions) ? suggestions.map((apt, index) => (
                                <span key={index}>{apt.numero}{index < suggestions.length - 1 ? ', ' : ''}</span>
                            )) : 'Nenhum apartamento disponível no momento.'}
                        </Alert>
                    )}

                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                        {/* 1. Seleção do Hóspede */}
                        <Autocomplete
                            options={hospedes}
                            getOptionLabel={(option) => option.nome}
                            value={hospedeSelecionado}
                            onChange={(event, newValue) => setHospedeSelecionado(newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label="Hóspede" fullWidth required />
                            )}
                        />

                        {/* 2. Seleção das Datas */}
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <DatePicker
                                label="Check-in"
                                value={checkin}
                                onChange={(newValue) => setCheckin(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth required />}
                            />
                            <DatePicker
                                label="Check-out"
                                value={checkout}
                                onChange={(newValue) => setCheckout(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth required />}
                            />
                        </Box>

                        {/* 3. Seleção de Adultos e Crianças */}
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <TextField
                                label="Adultos"
                                type="number"
                                value={adultos}
                                onChange={(e) => setAdultos(e.target.value)}
                                fullWidth
                                required
                            />
                            <TextField
                                label="Crianças"
                                type="number"
                                value={criancas}
                                onChange={(e) => setCriancas(e.target.value)}
                                fullWidth
                            />
                        </Box>

                        {/* 4. Seleção do Apartamento */}
                        <Autocomplete
                            options={apartamentos}
                            getOptionLabel={(option) => `${option.numero} - ${option.descricao}`}
                            value={apartamentoSelecionado}
                            onChange={(event, newValue) => setApartamentoSelecionado(newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label="Apartamento" fullWidth required />
                            )}
                        />

                        {/* 5. Seleção do Status */}
                        <TextField
                            select
                            label="Status da Reserva"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="pendente">Pendente</MenuItem>
                            <MenuItem value="reservada">Reservada</MenuItem>
                            <MenuItem value="confirmada">Confirmada</MenuItem>
                            <MenuItem value="cancelada">Cancelada</MenuItem>
                            <MenuItem value="finalizada">Finalizada</MenuItem>
                        </TextField>

                        {/* Desconto (opcional) */}
                        {reserva && (
                            <TextField
                                label="Desconto"
                                type="number"
                                value={desconto}
                                onChange={(e) => setDesconto(e.target.value)}
                                fullWidth
                            />
                        )}

                        <Typography variant="h6" gutterBottom align="center">
                            Valor Total: <strong>R${(valorTotal - desconto).toFixed(2)}</strong>
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                            <Button onClick={onClose} variant="outlined" color="error" sx={{ mr: 2 }}>
                                Cancelar
                            </Button>
                            <Button type="submit" variant="contained" color="success" disabled={loading}>
                                {loading ? 'Salvando...' : 'Salvar Reserva'}
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </LocalizationProvider>
        </Dialog>
    );
}

export default CriarReserva;
