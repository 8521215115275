import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FaWhatsapp } from 'react-icons/fa';
import api from '../apiConfigLogin';

function HospedeList({ onEdit, refresh, onDelete, hospedes }) {
    const [hospedesData, setHospedesData] = useState([]);  // Estado para armazenar os hóspedes

    useEffect(() => {
        if (hospedes.length > 0) {
            // Se houverem hóspedes filtrados, exibi-los
            setHospedesData(hospedes);
        } else {
            // Caso contrário, buscar todos os hóspedes
            api.get(`/hospedes`)
                .then(response => setHospedesData(response.data))
                .catch(error => console.error(error));
        }
    }, [refresh, hospedes]);  // Atualizar a lista quando a busca mudar ou o refresh for ativado

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedHospede, setSelectedHospede] = useState(null);

    const handleOpenDialog = (hospede) => {
        setSelectedHospede(hospede);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedHospede(null);
    };

    const handleConfirmDelete = () => {
        if (selectedHospede) {
            onDelete(selectedHospede.id);
        }
        handleCloseDialog();
    };

    const formatPhoneNumber = (telefone) => {
        const cleanedNumber = telefone.replace(/\D/g, '');
        
        return cleanedNumber.startsWith('55') ? cleanedNumber : `55${cleanedNumber}`;
    };
    
    const generateWhatsAppMessageUrl = (hospede) => {
        const message = `Olá, ${hospede.nome}, tudo bem?`;
        const encodedMessage = encodeURIComponent(message);
        const phoneNumber = formatPhoneNumber(hospede.telefone);
        
        return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    };
    
    const openWhatsApp = (hospede) => {
        const whatsappUrl = generateWhatsAppMessageUrl(hospede);
        window.open(whatsappUrl, '_blank');
    };
    
    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Telefone</TableCell>
                        <TableCell>Documento</TableCell>
                        <TableCell>Nascimento</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hospedesData.map((hospede) => (
                        <TableRow key={hospede.id}>
                            <TableCell>{hospede.nome}</TableCell>
                            <TableCell>{hospede.email}</TableCell>
                            <TableCell>{hospede.telefone}</TableCell>
                            <TableCell>{hospede.documento}</TableCell>
                            <TableCell>{hospede.aniversario}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => openWhatsApp(hospede)} color="primary">
                                    <FaWhatsapp />
                                </IconButton>
                                <IconButton onClick={() => onEdit(hospede)} color="success">
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleOpenDialog(hospede)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza que deseja excluir o hóspede <strong>{selectedHospede?.nome}</strong>? Essa ação não pode ser desfeita.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error">
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default HospedeList;
