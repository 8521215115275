import React, { useState } from 'react';
import { TextField, MenuItem, Button, Box, Snackbar, Alert, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import api from '../apiConfigLogin';

function BuscarHospede({ onBuscar }) {
    const [criterio, setCriterio] = useState('nome');
    const [valor, setValor] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showBusca, setShowBusca] = useState(false);

    const handleBuscar = () => {
        const rotaBusca = `/hospedes/${criterio}/${valor}`;

        api.get(rotaBusca)
            .then(response => {
                onBuscar(response.data);
                setSnackbarMessage('Busca realizada com sucesso!');
                setSnackbarSeverity('success');
            })
            .catch(error => {
                console.error('Erro ao buscar hóspedes:', error);
                setSnackbarMessage('Erro ao buscar hóspedes.');
                setSnackbarSeverity('error');
            })
            .finally(() => {
                setSnackbarOpen(true);
                setValor('');
            });
    };

    const handleRefresh = () => {
        // Lógica para buscar todos os hóspedes
        api.get(`/hospedes`)
            .then(response => {
                onBuscar(response.data); // Atualiza a lista de hóspedes com a resposta da API
                setSnackbarMessage('Lista de hóspedes atualizada!');
                setSnackbarSeverity('success');
            })
            .catch(error => {
                console.error('Erro ao atualizar a lista de hóspedes:', error);
                setSnackbarMessage('Erro ao atualizar a lista de hóspedes.');
                setSnackbarSeverity('error');
            })
            .finally(() => {
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleToggleBusca = () => {
        setShowBusca(prev => !prev); // Alternar a exibição da busca
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton onClick={handleToggleBusca}>
                <SearchIcon />
            </IconButton>

            {showBusca && (
                <>
                    <TextField
                        select
                        label="Critério de Busca"
                        value={criterio}
                        onChange={(e) => setCriterio(e.target.value)}
                        sx={{ minWidth: 150 }}
                        InputProps={{
                            style: { height: 40 } // Defina a altura aqui
                        }}
                    >
                        <MenuItem value="nome">Nome</MenuItem>
                        <MenuItem value="documento">Documento</MenuItem>
                        <MenuItem value="telefone">Telefone</MenuItem>
                        <MenuItem value="aniversario">Nascimento</MenuItem>
                    </TextField>

                    <TextField
                        label={`Buscar por ${criterio}`}
                        value={valor}
                        onChange={(e) => setValor(e.target.value)}
                        sx={{ minWidth: 400 }}
                        InputProps={{
                            style: { height: 40 } // Defina a altura aqui
                        }}
                    />

                    <IconButton variant="contained" color="primary" onClick={handleBuscar}>
                        <SearchIcon />
                    </IconButton>

                    <IconButton variant="contained" color="primary" onClick={handleRefresh}>
                        <RefreshIcon />
                    </IconButton>
                </>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BuscarHospede;
