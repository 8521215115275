import React, { useState, useEffect } from 'react';
import HospedeList from '../components/HospedeList';
import CriarHospede from '../components/CriarHospede';
import BuscaHospede from '../components/BuscarHospede';
import { IconButton, Typography, Dialog, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import api from '../apiConfigLogin';

function Hospedes() {
    const [open, setOpen] = useState(false);
    const [hospedeToEdit, setHospedeToEdit] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [filteredHospedes, setFilteredHospedes] = useState([]); 

    useEffect(() => {
        document.title = "Hóspedes | Sua Pousada";
    }, []);

    const handleClickOpen = () => {
        setHospedeToEdit(null);
        setOpen(true);
    };

    const handleEdit = (hospede) => {
        setHospedeToEdit(hospede);
        setOpen(true);
    };

    const handleDelete = (id) => {
        api.delete(`http://srv621768.hstgr.cloud:4000/api/hospedes/${id}`)
            .then(() => {
                setSnackbarMessage('Hóspede deletado com sucesso.');
                setSnackbarSeverity('success');
                setRefresh(!refresh);
            })
            .catch((error) => {
                setSnackbarMessage('Erro ao deletar hóspede.');
                setSnackbarSeverity('error');
                console.error(error);
            })
            .finally(() => setSnackbarOpen(true));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = (success, message) => {
        setRefresh(!refresh);
        setOpen(false);
        setSnackbarMessage(message);
        setSnackbarSeverity(success ? 'success' : 'error');
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSearch = (hospedesFiltrados) => {
        // Atualiza a lista de hóspedes filtrados
        setFilteredHospedes(hospedesFiltrados);
    };

    return (
        <div>
            <Typography variant="h4" style={{ display: 'flex', alignItems: 'center' }}>
                Hóspedes
                <IconButton onClick={handleClickOpen} color="primary" aria-label="add hóspede">
                    <AddIcon />
                </IconButton>
                <BuscaHospede onBuscar={handleSearch} /> {/* Passa a função de busca */}
            </Typography>
            <HospedeList onEdit={handleEdit} onDelete={handleDelete} refresh={refresh} hospedes={filteredHospedes} /> {/* Passa os hóspedes filtrados */}
            <Dialog open={open} onClose={handleClose}>
                <CriarHospede 
                    hospede={hospedeToEdit} 
                    onClose={handleClose} 
                    onSave={handleSave} 
                />
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Hospedes;
