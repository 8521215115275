import React, { useState, useEffect } from 'react';
import api from '../apiConfigLogin';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';

function DespesaList() {
    const [despesas, setDespesas] = useState([]);

    useEffect(() => {
        api.get('/despesas')
            .then(response => setDespesas(response.data))
            .catch(error => console.error('Erro ao carregar despesas:', error));
    }, []);

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Lista de Despesas
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {despesas.map(despesa => (
                            <TableRow key={despesa.id}>
                                <TableCell>{despesa.tipo}</TableCell>
                                <TableCell>{despesa.valor}</TableCell>
                                <TableCell>{new Date(despesa.data).toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" color="primary" style={{ marginTop: 20 }}>
                Adicionar Despesa
            </Button>
        </div>
    );
}

export default DespesaList;
