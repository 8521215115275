import React, { useState, useEffect } from 'react';
import ApartamentoList from '../components/ApartamentoList';
import CriarApartamento from '../components/CriarApartamento';
import { IconButton, Typography, Dialog, Snackbar, Alert, Button, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import api from '../apiConfigLogin';

function Apartamentos() {
    const [open, setOpen] = useState(false);
    const [apartamentoToEdit, setApartamentoToEdit] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [apartamentoToDelete, setApartamentoToDelete] = useState(null);

    useEffect(() => {
        document.title = "Apartamentos | Sua Pousada";
    }, []);

    const handleClickOpen = () => {
        setApartamentoToEdit(null);
        setOpen(true);
    };

    const handleEdit = (apartamento) => {
        setApartamentoToEdit(apartamento);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = (success, message) => {
        setRefresh(!refresh);
        setOpen(false);
        setSnackbarMessage(message);
        setSnackbarSeverity(success ? 'success' : 'error');
        setSnackbarOpen(true);
    };

    const handleDelete = async () => {
        try {
            await api.delete(`http://srv621768.hstgr.cloud:4000/api/apartamentos/${apartamentoToDelete}`);
            setSnackbarMessage('Apartamento deletado com sucesso!');
            setSnackbarSeverity('success');
            setRefresh(!refresh);
        } catch (error) {
            setSnackbarMessage('Erro ao deletar o apartamento.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
            setConfirmDialogOpen(false); // Fecha o diálogo de confirmação
        }
    };

    const handleConfirmDelete = (apartamentoId) => {
        setApartamentoToDelete(apartamentoId);
        setConfirmDialogOpen(true); // Abre o diálogo de confirmação
    };

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            <Typography variant="h4" style={{ display: 'flex', alignItems: 'center' }}>
                Apartamentos
                <IconButton onClick={handleClickOpen} color="success" aria-label="add apartamento">
                    <AddIcon />
                </IconButton>
            </Typography>
            <ApartamentoList 
                onEdit={handleEdit} 
                onDelete={handleConfirmDelete} 
                refresh={refresh} 
            />
            <Dialog open={open} onClose={handleClose}>
                <CriarApartamento 
                    apartamento={apartamentoToEdit} 
                    onClose={handleClose} 
                    onSave={(success, message) => handleSave(success, message)} 
                />
            </Dialog>

            {/* Dialog de Confirmação para Deletar */}
            <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza que deseja excluir este apartamento? Esta ação não pode ser desfeita.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Apartamentos;
