import React, { useEffect } from 'react';
import DespesaList from '../components/DespesaList';

function Despesas() {

    useEffect(() => {
        document.title = "Despesas | Sua Pousada";
    }, []);

    return (
        <div>
            <h1>Despesas</h1>
            <DespesaList />
        </div>
    );
}

export default Despesas;
