import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, Dialog } from '@mui/material';
import api from '../apiConfigLogin';

function CriarHospede({ hospede, onClose, onSave }) {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [documento, setDocumento] = useState('');

    useEffect(() => {
        if (hospede) {
            setNome(hospede.nome);
            setEmail(hospede.email);
            setTelefone(hospede.telefone);
            setDocumento(hospede.documento);
        } else {
            setNome('');
            setEmail('');
            setTelefone('');
            setDocumento('');
        }
    }, [hospede]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const novoHospede = {
            nome,
            email,
            telefone,
            documento
        };

        const url = hospede
            ? `/hospedes/${hospede.id}`  // Atualizar hóspede
            : `/hospedes`;  // Criar novo hóspede

        const method = hospede ? 'put' : 'post';

        api[method](url, novoHospede)
            .then(response => {
                onSave(true, `Hóspede ${hospede ? 'atualizado' : 'criado'} com sucesso!`);
                onClose();
            })
            .catch(error => {
                console.error('Erro ao salvar hóspede:', error);
                onSave(false, 'Erro ao salvar hóspede. Tente novamente.');
            });
    };

    return (
        <Dialog open={!!hospede || hospede === null} onClose={onClose} maxWidth="sm" fullWidth>
            <Container sx={{ padding: '30px' }}>
                <Typography variant="h5" align="center" gutterBottom>
                    {hospede ? 'Editar Hóspede' : 'Criar Hóspede'}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        fullWidth
                        required
                    />
                    <TextField
                        label="Telefone"
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Documento"
                        value={documento}
                        onChange={(e) => setDocumento(e.target.value)}
                        fullWidth
                        required
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                        <Button onClick={onClose} variant="outlined" color="error" sx={{ mr: 2 }}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {hospede ? 'Salvar Alterações' : 'Criar Hóspede'}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Dialog>
    );
}

export default CriarHospede;
