import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import api from '../apiConfigLogin';

function PasswordStrengthBar({ passwordStrength }) {
  let color = '';
  let value = 0;

  switch (passwordStrength) {
    case 'Forte':
      color = 'success';
      value = 100;
      break;
    case 'Média':
      color = 'warning';
      value = 50;
      break;
    case 'Fraca':
      color = 'error';
      value = 20;
      break;
    default:
      color = 'error';
      value = 0;
  }

  return <LinearProgress variant="determinate" value={value} color={color} />;
}

const defaultTheme = createTheme();

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Registro | Sua Pousada";
  }, []);

  const handleRequestVerification = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await api.post('/auth/request-email-verification', { email });
      setSuccess('Código de verificação enviado para seu email.');
      setShowVerification(true);
      setOpen(true);
    } catch (error) {
      setError('Erro ao enviar o email de verificação. Email já em uso.');
      setOpen(true);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    if (password !== confirmPassword) {
      setError('As senhas não coincidem.');
      setOpen(true);
      return;
    }
    try {
      const response = await api.post('/auth/register', { username, password, email, verificationCode });
      setSuccess('Usuário registrado com sucesso!');
      setOpen(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000); // Redireciona para a página de login após 2 segundos
    } catch (error) {
      setError('Erro ao registrar usuário. Código de verificação inválido.');
      setOpen(true);
    }
  };

  const checkPasswordStrength = (password) => {
    let strength = 'Fraca';
    if (password.length >= 8) {
      strength = 'Média';
    }
    if (/[A-Z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*]/.test(password)) {
      strength = 'Forte';
    }
    setPasswordStrength(strength);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registrar
          </Typography>
          {!showVerification ? (
            <Box component="form" onSubmit={handleRequestVerification} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Nome de Usuário"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  checkPasswordStrength(e.target.value);
                }}
              />
              <PasswordStrengthBar passwordStrength={passwordStrength} />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirmar Senha"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Solicitar Verificação de Email
              </Button>
              <Grid container justifyContent="center">
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Button
                    variant="text"
                    fullWidth
                    onClick={() => navigate('/login')}
                    style={{ display: 'block', textAlign: 'center', fontSize: '12px' }} 
                  >
                    Já tem uma conta? Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="verificationCode"
                label="Código de Verificação"
                name="verificationCode"
                autoComplete="verification-code"
                autoFocus
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Verificar Email e Registrar
              </Button>
            </Box>
          )}
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
          <Alert onClose={() => setOpen(false)} severity={error ? 'error' : 'success'}>
            {error || success}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default RegisterPage;
